<script>
import vue2Dropzone from "vue2-dropzone";
import Layout from "../../layouts/main";
import PageHeader from "@/components/Page-header";
import appConfig from "../../../../app.config";

/**
 * File Uploads component
 */
export default {
  page: {
    title: "File Uploads",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    vueDropzone: vue2Dropzone,
    Layout,
    PageHeader,
  },
  data() {
    return {
      title: "File Uploads",
      items: [
        {
          text: "Finex",
          href: "/",
        },
        {
          text: "Forms",
          href: "/",
        },
        {
          text: "File Uploads",
          active: true,
        },
      ],
      dropzoneOptions: {
        url: "https://httpbin.org/post",
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: {
          "My-Awesome-Header": "header value",
        },
        previewTemplate: this.template(),
      },
    };
  },
  methods: {
    template: function () {
      return ` <div class="dropzone-previews mt-3">
            <div class="card mt-1 mb-0 shadow-none border">
                <div class="p-2">
                    <div class="row align-items-center">
                        <div class="col-auto">
                            <img data-dz-thumbnail src="#" class="avatar-sm rounded bg-light" alt="">
                        </div>
                        <div class="col pl-0">
                            <a href="javascript:void(0);" class="text-muted font-weight-bold" data-dz-name></a>
                            <p class="mb-0" data-dz-size></p>
                        </div>
                        <div class="col-auto">
                            <!-- Button -->
                            <a href="" class="btn btn-link btn-lg text-muted" data-dz-remove>
                                <i class="fe-x"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        `;
    },
  },
  middleware: "router-auth",
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <h4 class="header-title m-t-0">Dropzone File Upload</h4>
            <p class="text-muted font-13 m-b-30">
              DropzoneJS is an open source library that provides drag’n’drop
              file uploads with image previews.
            </p>
            <!-- file upload -->
            <vue-dropzone
              id="dropzone"
              ref="myVueDropzone"
              :use-custom-slot="true"
              :options="dropzoneOptions"
            >
              <div class="dz-message needsclick">
                <i class="h1 text-muted ri-upload-cloud-2-line"></i>
                <h3>Drop files here or click to upload.</h3>
                <span class="text-muted font-13"
                  >(This is just a demo dropzone. Selected files are
                  <strong>not</strong> actually uploaded.)</span
                >
              </div>
            </vue-dropzone>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
